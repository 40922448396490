<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">File Uploads</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>

                Report Type
                <select id="report_type" name="report_type" class="form-select" v-model="selectedReportType" @change="getData" required>
                  <option v-for="element in report_types" :value="element.name" :key="element.id">
                    {{ element.name + ' - ' + element.code }}
                  </option>
                </select>

                <!-- The hidden file input field -->
                <input type="file" ref="fileInput" @change="onFileSelected" style="display: none" />

                <!-- Argon button triggers the file input -->
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="triggerFileInput">
                  Upload
                </argon-button>

              </div>
            </div>
            <div class="card-body">
              File last loaded on 2024-09-08 - filename was: FYM 3-22-24 NACL Audit.xlsx
              <br><br>
              <h5 class="mb-1">Preflight checks</h5>
              <br>
              This filename you are uploading: FYM 4-22-24 NACL Audit.xlsx
              <br>
              <p>File definition has 3 tabs to process</p>
              <li><i class='fa fa-check fa-lg' style='color:#99ff33' title='Loaded'></i>MAPDs (29 columns) Target table: mapds</li>
              <li><i class='fa fa-check fa-lg' style='color:#99ff33' title='Loaded'></i>NACL  (31 columns) Target table: nacl</li>
              <li><i class='fa fa-check fa-lg' style='color:#99ff33' title='Loaded'></i>PDPs  (31 columns) Target table: pdps</li>
              <br>
              Uploaded file matches definitions. Click below to process
              <br>
              Reports group id:
              <select>
                <option>53</option>
              </select>&nbsp;
              <argon-button color="success" size="sm" class="ms-auto" @click.prevent="triggerFileInput">
                Load Report
              </argon-button>

              <ul class="ul" hidden>
                <li>
                  <span class="td1 heading">
                    id 
                  </span>
                  <span class="td2 heading"> 
                    created_at
                  </span>
                  <span class="td5 heading"> 
                    name
                  </span>
                  <span class="td1 heading"> 
                    type
                  </span>
                  <span class="td2 heading"> 
                    date_received
                  </span>
                  <span class="td13 heading"> 
                    year
                  </span>
                  <span class="td13 heading"> 
                    month
                  </span>
                  <span class="td13 heading"> 
                    period
                  </span>
                </li>
              </ul>

              <ul class="ul" v-for="element in elements" v-bind:key="element.id" hidden>
                  <li>
                    
                    <span class="td1">
                      <i class='fa fa-times fa-lg' style='color:#ff4d4d' title='Load failed'></i>
                      <i class='fa fa-check fa-lg' style='color:#99ff33' title='Loaded'></i>
                      {{element.id}}
                    </span>
                    
                    <span class="td2">
                      {{element.created_at.substring(0,10)}}
                    </span>

                    <span class="td5">
                      {{element.name}}
                    </span>

                    <span class="td1">
                      {{element.type}}
                    </span>

                    <span class="td2">
                      {{element.date_received.substring(0,10)}}
                    </span>

                    <span class="td13">
                      {{element.year}}
                    </span>

                    <span class="td13">
                      {{element.month}}
                    </span>

                    <span class="td13">
                      {{element.period}}
                    </span>

                    <br><br>
                  </li>
              </ul>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

const company = sessionStorage.getItem("company");
var message = "";

export default {
  name: "report_types",
  data() {
    return {
      showMenu: false,
      elements: [],
      report_types: [],
      selectedReportType: 'HEAP',
      company,
      message,
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    async getData() {
      let response = await fetch("/apis/view/FileUploads/" + this.selectedReportType);
      this.elements = await response.json();
      //this.message = this.elements.length + ' Files';
    },
    async getLookups() {
      let response2 = await fetch("/apis/get_lu/file_uploads");
      this.report_types = await response2.json();
    },
    async newelement() {
      router.push({ name: 'FileUpload', params: {id: 0}});
    },
    // Trigger the hidden file input
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    // Handle file selection
    async onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        // Create a FormData object to send the file
        const formData = new FormData();
        formData.append('file', file);

        try {
          // Send the file to the backend using Fetch API
          const response = await fetch('/apis/upload/' + this.selectedReportType, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('File upload failed');
          }

          const data = await response.json();
          console.log('File uploaded successfully:', data);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    },
  },
  components: { Navbar, ArgonButton },
  created() {    
    this.getLookups();
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>